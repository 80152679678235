import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  .hero-section {
    background: url("/assets/hero-d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 50vh;
    .hero-section-content {
      display: flex;
      width: 90%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin-bottom: 0;
        color: #fff;
      }
      p {
        color: #fff;
        margin: 0;
        width: 100%;
      }
    }
  }
  .hero-section-content-m {
    width: 90%;
    margin: 1rem auto;
  }
  .biografy-section {
    width: 90%;
    margin: 6% auto;
    h2 {
      margin: 2rem auto;
      text-align: center;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        width: 100%;
        &:last-of-type {
          align-self: flex-start;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .art-section {
    width: 90%;
    margin: 6% auto;
    text-align: center;
    p {
      text-align: left;
      width: 100%;
      margin: 2rem auto;
    }
    .artwork-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      min-height: 500px;
      &.reversed {
        flex-direction: row-reverse;
        .content-container {
          text-align: right;
          align-items: flex-end;
        }
      }
      img {
        height: auto;
        width: 100%;
        max-width: 300px;
      }
      svg {
        position: absolute;
      }
      .image-container {
        width: 50%;
      }
      .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 35%;
        h3 {
          margin-bottom: 0;
          width: fit-content;
        }
        p {
          margin: 1rem 0 0;
          color: gray;
          width: fit-content;
        }
      }
    }
  }
  .closing-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 6% auto;
    justify-content: space-around;
    img {
      width: 100%;
    }
    div {
      width: 100%;
      p {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
      .hero-section-content {
        display: flex;
        p {
          width: 50%;
        }
      }
    }
    .biografy-section {
      div {
        flex-direction: row;
        p {
          width: 25%;
          &:last-of-type {
            align-self: flex-end;
          }
        }
        img {
          width: 40%;
        }
      }
    }
    .art-section {
      p {
        width: 80%;
        margin: 2rem auto;
        text-align: center;
      }
    }
    .closing-section {
      display: flex;
      flex-direction: row;
      img {
        width: 40%;
      }
      div {
        width: 50%;
        p {
          width: 80%;
          &:last-of-type {
            align-self: flex-end;
            padding-left: 5rem;
            width: 80%;
          }
        }
      }
    }
  }
`;
