import React, { useEffect, useRef, useState } from "react";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";

const GetInTouch = ({ jsonData }) => {
  return (
    <Container className="">
      <h2 className={styles.dynamicTitleh2}>{jsonData?.get_in_touch_title}</h2>
      <p>{jsonData?.get_in_touch_subtitle}</p>
      <div className="flex_center_space-between main-container">
        <div className="">
          <div className="contact-container wpp">
            <a
              aria-label="Chat on WhatsApp"
              href="https://wa.me/393392264841"
              target="_blank"
            >
              <img
                alt="Chat on WhatsApp"
                src="/assets/WhatsAppButtonGreenLarge.svg"
              />
            </a>
          </div>
          <div className="contact-container">
            <a
              href="tel:+393392264841"
              target="_blank"
              style={{
                color: "black",
                fontWeight: 500,
                fontFamily: "Gill Sans",
                display: "flex",
                textDecoration: "none",
                fontSize: 16,
              }}
            >
              <img
                className="phone-icon"
                src="../../assets/phone icon.svg"
                alt="Phone icon"
                target="_blank"
              />
              <p>{jsonData?.theme_page_phone}</p>
            </a>
          </div>
          <div className="contact-container">
            <a
              href="mailto:info@celliniproject.com"
              target="_blank"
              style={{
                color: "black",
                fontWeight: 500,
                fontFamily: "Gill Sans",
                display: "flex",
                textDecoration: "none",
                fontSize: 16,
              }}
            >
              <img
                className="mail-icon"
                src="../../assets/mail icon.svg"
                alt="Cellini project logo"
              />
              <p>{jsonData?.theme_page_mail}</p>
            </a>
          </div>
        </div>
        <div className="">
          <h3 className={styles.dynamicTitleh3}>
            {jsonData?.get_in_touch_where_we_are}
          </h3>
          <p>
            Piazza del Mercato Centrale 18,
            <br />
            50123 Firenze FL, <br />
            Italia
          </p>
          <a
            href="https://www.google.com/maps?ll=43.776883,11.254051&z=16&t=m&hl=en&gl=AR&mapclient=embed&q=Piazza+del+Mercato+Centrale,+18+50123+Firenze+FI+Italy"
            target="_blank"
          >
            {jsonData?.get_in_touch_open_maps}
          </a>
        </div>
      </div>
    </Container>
  );
};

export default GetInTouch;
