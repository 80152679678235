import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Testimonials } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";
import { Link } from "react-router-dom";

function Four0four({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);
  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData} isNavbarBlack={true}>
      <Container>
        <div className="error-container">
          <h1 className={styles.dynamicTitleh2}>{jsonData?.four0four_title}</h1>
          <Link to="/" className="btn-primary">
            {jsonData?.four0four_button}
          </Link>
        </div>
      </Container>
    </Layout>
  );
}

export default Four0four;
