import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Container } from "./styled";
import { GetInTouch } from "../../components";
import styles from "../../styles/Home.module.scss";

function About({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <h1 className={styles.dynamicTitle}>
              {jsonData?.about_hero_title}
            </h1>
            <p>{jsonData?.about_hero_subtitle}</p>
          </div>
        </div>
        <div className="box  founders-section">
          <h2 className={styles.dynamicTitleh2}>
            {jsonData?.about_founders_title}
          </h2>
          <div className="flex_center_space-between">
            <div className="founder-container">
              <img
                className="toScaleImage"
                src="./assets/founder-image.jpg"
                alt="Project Cellini founders image"
              />
              <h3 className={styles.dynamicTitleh3}>
                {jsonData?.about_founder_name}
              </h3>
              <p>{jsonData?.about_founder_position}</p>
            </div>
            <div className="founder-container">
              <img
                className="toScaleImage"
                src="./assets/Founder-image-2.jpg"
                alt="Project Cellini founders image"
              />
              <h3 className={styles.dynamicTitleh3}>
                {jsonData?.about_founder2_name}
              </h3>
              <p>{jsonData?.about_founder2_position}</p>
            </div>
          </div>
        </div>
        <div className="side-box  flex_center_space-between story-section">
          <img
            className="toScaleImage"
            src="./assets/founder-image.jpg"
            alt="Project Cellini founders image"
          />
          <div>
            <h2 className={styles.dynamicTitleh2}>
              {jsonData?.about_story_title}
            </h2>
            <p className="title">{jsonData?.about_story_subtitle}</p>
            <p>{jsonData?.about_story_text}</p>
          </div>
        </div>
        <div className="side-box-reversed flex_center_space-between story-section reversed">
          <img
            className="toScaleImage"
            src="./assets/founder-image.jpg"
            alt="Project Cellini founders image"
          />
          <div>
            <h2 className={styles.dynamicTitleh2}>
              {jsonData?.about_story2_title}
            </h2>
            <p className="title">{jsonData?.about_story2_subtitle}</p>
            <p>{jsonData?.about_story2_text}</p>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default About;
