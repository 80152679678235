import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navbar, Footer } from "../../components";
import { ContainerBody } from "./styled";

function Layout({ children, setLanguage, isNavbarBlack, jsonData }) {
  return (
    <>
      <ContainerBody>
        <Navbar
          setLanguage={setLanguage}
          isNavbarBlack={isNavbarBlack}
          jsonData={jsonData}
        />
        {children}
        <Footer />
      </ContainerBody>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
