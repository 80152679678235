import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 10vh;
  width: calc(100vw - 10%);
  max-width: 1920px;
  background-color: transparent;
  padding: 0 5%;
  position: fixed;
  top: 0;
  z-index: 10;
  transition: background-color 0.5s ease;
  &.isNotTop {
    background-color: #fff;
    transition: background-color 0.5s ease;
    .servicis {
      color: #000;
      transition: color 0.5s ease;
      .languagge-icon {
        .cls-1,
        .cls-2 {
          fill: none;
          stroke: #000;
          stroke-miterlimit: 10;
        }
        .cls-1 {
          stroke-width: 1.55px;
        }
        .cls-2 {
          stroke-width: 2.1px;
        }
      }
      .menu-icon {
        .cls-1 {
          fill: none;
          stroke: #000;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 2.73px;
        }
      }
      .location-icon {
        .cls-1 {
          fill: #000;
          fill-rule: evenodd;
        }
      }
    }
  }
  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .navbarLogo {
      width: 5%;
      height: 5%;
      min-height: 30px;
      min-width: 30px;
    }
    h4 {
      width: 70%;
      font-family: "Roboto", sans-serif;
      display: none;
    }
  }
  ul {
    display: flex;
    list-style: none;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding: 0;
    li {
      list-style: none;
      width: fit-content;
      padding: 0 1rem 0 0;
      a {
        color: #000;
        width: 100%;
        font-weight: 400;
        font-family: "Poppins";
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
      }
      &:last-of-type {
        padding: 0;
      }
    }
    .servicis {
      color: #fff;
      font-family: "Poppins";
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: color 0.5s ease;
      svg {
        height: 20px;
        margin: 1rem 0;
      }
      .languagge-icon {
        .cls-1,
        .cls-2 {
          fill: none;
          stroke: #fff;
          stroke-miterlimit: 10;
        }
        .cls-1 {
          stroke-width: 1.55px;
        }
        .cls-2 {
          stroke-width: 2.1px;
        }
      }
      .menu-icon {
        .cls-1 {
          fill: none;
          stroke: #fff;
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 2.73px;
        }
      }
      .location-icon {
        .cls-1 {
          fill: #fff;
          fill-rule: evenodd;
        }
      }
      .servicis-submenu {
        display: block;
        top: 100%;
        left: 0;
        width: 100px;
        padding: 0.5rem;
        position: absolute;
        background: #f5f5f5;
        color: #000;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s ease;
        li {
          width: fit-content;
          padding: 0.5rem 0;
          &:hover {
            background: #ccc;
          }
          a {
            font-size: 16px;
          }
        }
      }
    }
    .servicis:hover > .servicis-submenu {
      visibility: visible;
      opacity: 1;
    }
    #logoutButton {
      .visible {
        display: block;
      }
      &.hidden {
        display: none;
      }
    }
  }
  img {
    width: 40%;
  }
  @media (min-width: 867px) {
    padding: 0 5%;
    width: calc(100vw - 10%);
    .logo-container {
      .navbarLogo {
        width: 5%;
      }
      h4 {
        width: auto;
        padding-left: 1rem;
        display: block;
        justify-content: center;
      }
    }
    ul {
      li a {
        font-size: 16px;
      }
      .servicis {
        font-size: 16px;
        svg {
          height: 30px;
        }
      }
    }
    ul .servicis .servicis-submenu {
      width: 150px;
      top: 10.5vh;
      li a {
        font-size: 14px;
      }
    }
  }
`;
