import styled from "styled-components";

export const ContainerBody = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
`;
