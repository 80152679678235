import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  position: relative;
  .apartment-description {
    width: 90%;
    margin: 0 auto 6%;
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .location {
        display: flex;
        align-items: center;
        justify-content: center;
        .location-icon {
          height: 30px;
          margin-right: 1rem;
        }
      }
    }
    .content {
      p {
        width: 100%;
        margin: 2rem 0;
      }
    }
  }
  .amenities {
    background: #faf4e4;
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
    h2 {
      margin: 0;
    }
    .amenities-container {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      flex-wrap: wrap;
      .service-item {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        flex-basis: 25%;
        img {
          height: 20px;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .about-house {
    width: 90%;
    margin: 6% auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div,
    iframe {
      width: 100%;
    }
  }
  @media screen and (min-width: 867px) {
    .apartment-description {
      .header {
        width: 50%;
      }
      .content {
        p {
          width: 80%;
        }
      }
    }
    .amenities {
      .amenities-container {
        justify-content: flex-start;
        .service-item {
          flex-basis: 15%;
        }
      }
    }
    .about-house {
      flex-direction: row;
      div,
      iframe {
        width: 50%;
      }
    }
  }
`;
