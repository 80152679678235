import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Home,
  About,
  Apartments,
  Style,
  Four0four,
  Offers,
  SpecialOffer,
} from "./containers";
import React, { useState } from "react";
function App() {
  const [language, setLanguage] = useState("eng");
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Home {...props} setLanguage={setLanguage} language={language} />
          )}
        />
        <Route
          path="/apartments"
          render={(props) => (
            <Apartments
              {...props}
              setLanguage={setLanguage}
              language={language}
            />
          )}
        />
        <Route
          path="/about"
          render={(props) => (
            <About {...props} setLanguage={setLanguage} language={language} />
          )}
        />
        <Route
          path="/style"
          render={(props) => (
            <Style {...props} setLanguage={setLanguage} language={language} />
          )}
        />
        <Route
          path="/offers"
          render={(props) => (
            <Offers {...props} setLanguage={setLanguage} language={language} />
          )}
        />
        <Route
          path="/special-offer/:id"
          render={(props) => (
            <SpecialOffer
              {...props}
              setLanguage={setLanguage}
              language={language}
            />
          )}
        />
        <Route
          path="/404"
          render={(props) => (
            <Four0four
              {...props}
              setLanguage={setLanguage}
              language={language}
            />
          )}
        />
        <Route
          path="*"
          render={(props) => (
            <Four0four
              {...props}
              setLanguage={setLanguage}
              language={language}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
