import React, { useState, useEffect } from "react";
import { Container } from "./styled";
import { Link, useHistory } from "react-router-dom";

const Navbar = ({ setLanguage, isNavbarBlack, jsonData }) => {
  const [clientWindowHeight, setClientWindowHeight] = useState("");

  const history = useHistory();

  //HANDLE SCROLL FOR HEADER
  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Container
      className={`finish-button-nav ${
        clientWindowHeight >= 15 || isNavbarBlack === true ? "isNotTop" : null
      }`}
      isNavbarBlack={isNavbarBlack}
    >
      <ul>
        <li className="servicis">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 51.4 32.73"
            className="menu-icon toScaleImage"
          >
            <title>Recurso 8100</title>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Home_v2" data-name="Home v2">
                <line class="cls-1" x1="1.36" y1="1.36" x2="50.04" y2="1.36" />
                <line
                  class="cls-1"
                  x1="1.36"
                  y1="16.42"
                  x2="50.04"
                  y2="16.42"
                />
                <line
                  class="cls-1"
                  x1="1.36"
                  y1="31.36"
                  x2="50.04"
                  y2="31.36"
                />
              </g>
            </g>
          </svg>
          MENU
          <ul id="servicis-submenu" className="servicis-submenu">
            <li>
              <Link
                to="/apartments"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {jsonData?.navbar_apartment_link}
              </Link>
            </li>
            <li>
              <Link
                to="/style"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {jsonData?.navbar_style_link}
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {jsonData?.navbar_about_link}
              </Link>
            </li>
            <li>
              <Link
                to="/offers"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {jsonData?.navbar_offers_link}
              </Link>
            </li>
          </ul>
        </li>
        {/* <li className="servicis">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 39.79 39.79"
            className="languagge-icon toScaleImage"
          >
            <title>Recurso 7100</title>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Home_v2" data-name="Home v2">
                <path
                  class="cls-1"
                  d="M2,21.8l5.78.07c1.59-1.68,3.83-3.6,5-.36.42,4.66,5.52,5.7,4.79.39-1.46-1.63-1.06-2.34.85-2.31l1.68-1.32a2.49,2.49,0,0,1,2.83-2.46l-.36-5.07C20.6,9.08,19.91,7.46,22,6l4.64-3"
                />
                <path
                  class="cls-1"
                  d="M32.52,33.51l-1.15-.93-2.91-2.31-5.1.06c-2.57-1.87-2.71-3.63-1.1-5.33l5-2.31c1.14-.7,2.19-1.94,3.84.83,1.43-2,4.12-2.69,3,1l2.37,2,.72.44"
                />
                <circle class="cls-2" cx="19.9" cy="19.9" r="18.85" />
              </g>
            </g>
          </svg>
          ENG
          <ul id="servicis-submenu" className="servicis-submenu">
            <li onClick={(e) => setLanguage("it")}>ITA</li>
            <li onClick={(e) => setLanguage("eng")}>ENG</li>
          </ul>
        </li> */}
        <li
          className="servicis"
          onClick={() => {
            const win = window.open(
              "https://www.google.com/maps?ll=43.776883,11.254051&z=16&t=m&hl=en&gl=AR&mapclient=embed&q=Piazza+del+Mercato+Centrale,+18+50123+Firenze+FI+Italy",
              "_blank"
            );
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25.21 39.23"
            className="location-icon toScaleImage"
          >
            <title>Recurso 6100</title>
            <g id="Capa_2" data-name="Capa 2">
              <g id="Home_v2" data-name="Home v2">
                <path
                  class="cls-1"
                  d="M12.53,39.23l-1.39-2.46C9.59,34,4.34,24.72,2.64,21.61A21.5,21.5,0,0,1,0,12.5,12.67,12.67,0,0,1,12.53,0,12.7,12.7,0,0,1,25.21,12.5a21.63,21.63,0,0,1-2.62,9.11c-2.17,3.72-8.51,15.16-8.67,15.16Zm0-36h0A9.44,9.44,0,0,0,3.11,12.5a18.84,18.84,0,0,0,2.31,7.59C6.8,22.55,10.37,29,12.53,32.9c2.17-3.72,5.73-10.2,7.27-12.81A17.9,17.9,0,0,0,22,12.5a9.13,9.13,0,0,0-2.79-6.63,9.48,9.48,0,0,0-6.65-2.64Zm0,15.6h0a6.25,6.25,0,1,1,6.35-6.33,6.22,6.22,0,0,1-6.35,6.33Zm0-9.42h0a3.1,3.1,0,0,0,0,6.19,3.25,3.25,0,0,0,3.25-3.1,3.25,3.25,0,0,0-3.25-3.09Z"
                />
              </g>
            </g>
          </svg>
          LOC
        </li>
      </ul>
      <div
        className="logo-container"
        onClick={() => {
          history.push("/");
          window.scroll(0, 0);
        }}
      >
        <Link to="/">
          <img
            className=""
            src="../../assets/Logo estudio un paso mas.png"
            alt="Cellini project logo"
          />
        </Link>
      </div>
      <a className="btn-primary" href="/#contactForm">
        Book now
      </a>
    </Container>
  );
};
export default Navbar;
