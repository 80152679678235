import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Container } from "./styled";
import { GetInTouch } from "../../components";
import styles from "../../styles/Home.module.scss";
import { useLocation, useHistory } from "react-router-dom";

function SpecialOffer({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);
  const location = useLocation();
  const history = useHistory();

  let slug = location.pathname.replace("/special-offer/", "");
  console.log(slug);

  useEffect(() => {
    if (slug !== "honeymoon") history.push("/404");

    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <h1 className={styles.dynamicTitle}>
              {slug === "honeymoon" ? jsonData?.offers_hero_title : null}
            </h1>
          </div>
        </div>
        <div className="box  package-title-section">
          <h2 className={styles.dynamicTitleh3}>
            {slug === "honeymoon" ? jsonData?.honeymoon_offer_title : null}
          </h2>
          <p>
            {slug === "honeymoon" ? jsonData?.honeymoon_offer_subtitle : null}
          </p>
        </div>
        <div className="side-box  flex_center_space-between story-section">
          <img
            className="toScaleImage"
            src={`/assets/${slug !== undefined ? slug : null}-1.jpg`}
            alt="Project Cellini founders image"
          />
          <div>
            <h2 className={styles.dynamicTitleh3}>
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_first_title
                : null}
            </h2>
            <p className="title">
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_first_subtitle
                : null}
            </p>
            <p>
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_first_text
                : null}
            </p>
            {slug === "honeymoon"
              ? jsonData?.honeymoon_offer_first_list.map((item, i) => (
                  <p key={i} style={{ margin: "0" }}>
                    {item}
                  </p>
                ))
              : null}
          </div>
        </div>
        <div className="side-box-reversed flex_center_space-between story-section reversed">
          <img
            className="toScaleImage"
            src={`/assets/${slug !== undefined ? slug : null}-2.jpg`}
            alt="Project Cellini founders image"
          />
          <div>
            <h2 className={styles.dynamicTitleh3}>
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_second_title
                : null}
            </h2>
            <p className="title">
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_second_subtitle
                : null}
            </p>
            <p>
              {slug === "honeymoon"
                ? jsonData?.honeymoon_offer_second_text
                : null}
            </p>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default SpecialOffer;
