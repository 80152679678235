import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  .hero-section {
    background: url("/assets/offers-hero.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 50vh;
    .hero-section-content {
      width: 90%;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        margin-bottom: 0;
        color: #fff;
      }
      p {
        color: #fff;
        margin: 0;
        width: 100%;
      }
    }
  }
  .package-title-section {
    text-align: flex-start;
    width: 90%;
    margin: 6% auto;
    p {
      width: 70%;
    }
  }
  .story-section {
    width: 90%;
    margin: 6% auto;
    flex-direction: column;
    img {
      width: 100%;
    }
    div {
      width: 100%;
      h2 {
        margin-bottom: 0;
      }
      .title {
        color: #ffbd00;
        margin: 0;
        font-size: clamp(18px, 4vw, 30px);
        margin: 0px 0px 4rem;
      }
    }
  }
  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
      .hero-section-content p {
        color: #fff;
        margin: 0;
        width: 50%;
      }
    }

    .story-section {
      flex-direction: row;
      &.reversed {
        flex-direction: row-reverse;
      }
      img {
        width: 48%;
      }
      div {
        width: 45%;
      }
    }
  }
`;
