import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  .hero-section {
    background: url("/assets/hero-d.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 50vh;
    .hero-section-content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      h1 {
        margin-top: 0;
        color: #fff;
      }
      p {
        color: #ffbd00;
        margin: 3rem 0 0;
        font-size: clamp(18px, 4vw, 30px);
      }
    }
  }
  .apartments-section {
    text-align: center;
    width: 90%;
    margin: 4rem auto;
    h2 {
      font-family: "Garamond";
      margin: 0;
    }
  }
  .servicis-section {
    background: #faf4e4;
    display: flex;
    padding: 1rem;
    justify-content: center;
    width: 90%;
    margin: 4rem auto;
    flex-wrap: wrap;
    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 2rem;
      width: 25%;
      img {
        height: 30px;
      }
    }
  }
  .style-section {
    width: 90%;
    margin: 0 auto;
    position: relative;
    .top-container {
      width: 100%;
      margin: 4rem 0;
      p {
        width: 60%;
      }
      .statue-image {
        position: absolute;
        width: auto;
        height: 35%;
        right: 0;
        top: 0;
      }
    }
    .bottom-container {
      display: flex;
      flex-direction: column;
      padding: 0;
      img {
        width: 100%;
      }
      div {
        width: 90%;
        background: #fff;
        padding: 0;
        margin-top: 2rem;
        .title {
          color: #ffbd00;
          margin: 0;
          font-size: clamp(18px, 4vw, 30px);
        }
        h2 {
          margin: 0;
        }
      }
    }
  }
  .experience-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 2rem auto;
    justify-content: space-between;
    img {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
  .location-section {
    display: flex;
    flex-direction: column;
    padding: 5%;
    width: 90%;
    margin: 0 auto;
    img {
      width: 100%;
    }
    div {
      width: 100%;
      background: #fff;
      padding: 5% 0;
      .title {
        color: #ffbd00;
        margin: 0;
        font-size: clamp(18px, 4vw, 30px);
      }
      h2 {
        margin: 0;
      }
    }
  }
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 1rem auto;
    .contact-info-container {
      width: 100%;
      .contact-info-data {
        border-bottom: 1px solid black;
        padding: 1rem 0;
      }
      .contact-info-buttons {
        padding: 1rem 0;
        border-bottom: 1px solid black;
        .contact {
          display: flex;
          img {
            width: 30px;
            margin-right: 1rem;
          }
        }
      }
      .contact-info-messagge {
        padding-bottom: 3rem;
        .title-section {
          display: flex;
          img {
            width: 30px;
            margin-right: 1rem;
          }
        }
        p {
          margin-left: calc(30px + 1rem);
        }
      }
    }
    .contact-form-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (min-width: 867px) {
    .hero-section {
      height: 80vh;
    }
    .servicis-section {
      .service-item {
        width: 7%;
      }
    }
    .style-section {
      .top-container .statue-image {
        right: 5vw;
      }
      .bottom-container {
        background: url("/assets/style background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        flex-direction: row-reverse;
        padding: 5%;
        div {
          width: 30%;
          background: #fff;
          padding: 5%;
          h2 {
            margin: 0 0 4rem 0;
          }
        }
      }
    }
    .experience-section {
      flex-direction: row;
      margin: 5rem auto;
      img {
        width: 40%;
        transition: transform 0.2s;
        :hover {
          transform: scale(1.1);
        }
      }
      div {
        width: 50%;
      }
    }
    .location-section {
      background: url("/assets/home-location.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      flex-direction: row-reverse;
      width: 80%;
      div {
        width: 30%;
        background: #fff;
        padding: 5%;
        h2 {
          margin: 0 0 4rem 0;
        }
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .hero-section {
      background: url("/assets/hero-d.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .services-section {
      flex-direction: row;
      margin-top: -5%;
      .services-item {
        padding: 1rem;
      }
    }
    .home-options-container {
      flex-direction: row;
      .image-container {
        width: 50%;
      }
      .about-container {
        width: 50%;
        p {
          width: 70%;
        }
      }
    }
    .cta-section {
      padding: 3rem;
    }
    .contact-section {
      flex-direction: row;
      .contact-info-container {
        width: 35%;
        padding-right: 1rem;
      }
      .contact-form-container {
        width: 65%;
      }
    }
  }
`;
