import React, { useState, useEffect } from "react";
import { Container, GalleryContainer } from "./styled";

const ApartmentGallery = ({ jsonData }) => {
  const [isGaleryActive, setIsGaleryActive] = useState(false);
  const [listOfImages, setListOfImages] = useState([]);

  const importAll = (r) => {
    let newValue = r.keys();
    return newValue;
  };
  useEffect(() => {
    let listOfImagesTosave = importAll(
      require.context(
        "../../../public/assets/Apartment",
        false,
        /\.(png|jpe?g|svg)$/
      )
    ).map((image) => image.replace(".", ""));
    setListOfImages(listOfImagesTosave);
  }, []);

  return (
    <>
      <Container>
        <div className="right-container">
          <img
            src="./assets/apartmanet-1.jpg"
            alt="apartment descreptive image"
            loading="lazy"
          />
        </div>
        <div className="left-container">
          <div className="top-container">
            <img
              src="./assets/apartmanet-2.jpg"
              alt="apartment descreptive image"
              loading="lazy"
            />
            <img
              src="./assets/apartmanet-4.jpg"
              alt="apartment descreptive image"
              loading="lazy"
            />
          </div>
          <div className="bottom-container">
            <img
              src="./assets/apartmanet-3.jpg"
              alt="apartment descreptive image"
              loading="lazy"
            />
          </div>
        </div>
        <div className="showAll-btn" onClick={(e) => setIsGaleryActive(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            width="16"
            height="16"
          >
            <path
              fill-rule="evenodd"
              d="M3 11.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
              fill="#222222"
            />
          </svg>
          <p>{jsonData?.theme_page_show_images}</p>
        </div>
      </Container>
      {isGaleryActive ? (
        <GalleryContainer>
          <div className="gallery-header">
            <button onClick={(e) => setIsGaleryActive(false)}>
              <svg
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17.56 28.69"
                width="17.56"
                height="28.69"
              >
                <defs>
                  <style>{`.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4.98px;}`}</style>
                </defs>
                <title>Recurso 19100</title>
                <g id="Capa_2" data-name="Capa 2">
                  <g id="Home_v2" data-name="Home v2">
                    <path
                      className="cls-1"
                      d="M15.07,26.2,3.34,16.47A2.34,2.34,0,0,1,3.22,13l11-10.48"
                    ></path>
                  </g>
                </g>
              </svg>
              {jsonData?.theme_page_back_button}
            </button>
          </div>
          <div className="gallery-content">
            {listOfImages.map((image, index) => (
              <img
                key={index}
                src={`../assets/Apartment${image}`}
                alt="apartment descreptive image"
              />
            ))}
          </div>
        </GalleryContainer>
      ) : null}
    </>
  );
};

export default ApartmentGallery;
