import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  position: relative;
  background: url("/assets/hero-d.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10vh;
  .error-container {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;
