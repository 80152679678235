import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 1920px;
  background-color: #fff;
  .hero-section {
    text-align: center;
    width: 90%;
    margin: 15vh auto 6%;
    p {
      width: 80%;
      margin: 0 auto;
    }
  }
  .content-container {
    display: flex;
    width: 90%;
    height: 100%;
    margin: 0 auto 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
    .card-container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
      border-radius: 16px;
      &:hover {
        box-shadow: rgba(145, 158, 171, 0.5) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.5) 0px 12px 24px -4px;
      }
      .data-container {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 70%;
        }
        .content-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          row-gap: 0;
          h3 {
            flex: 1;
          }
          p {
            flex: 1;
          }
          a.disabled {
            cursor: no-drop;
            color: gray;
          }
        }
      }
    }
  }
  @media screen and (min-width: 867px) {
    .content-container {
      display: grid;
      grid-template-columns: 32% 32% 32%;
      grid-auto-rows: auto;
      height: 50%;
      .card-container {
        width: 90%;
        height: 100%;
        cursor: pointer;
        .data-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          img {
            width: 100%;
            height: auto;
            flex: 1;
          }
        }
      }
    }
  }
`;
