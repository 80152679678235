import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1920px;
  height: auto;
  margin: 2rem auto;
  padding: 0 5%;
  h2 {
    margin-bottom: 0;
  }
  .main-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    a {
      color: #ffbd00;
      font-weight: 700;
    }
  }
  .contact-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    background: #f3f3f3;
    border-radius: 24px;
    cursor: pointer;
    &.wpp {
      background: none;
    }
    .phone-icon,
    .mail-icon {
      width: 30px;
      padding-right: 1rem;
    }
  }
  @media (min-width: 867px) {
    .main-container {
      flex-direction: row;
      width: 55%;
    }
    .contact-container {
      width: 100%;
      margin: 1rem auto;
      align-items: space-around;
    }
  }
`;
