import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width:1920px
  height: 10vh;
  margin: 0 auto;
  padding: 0 5%;
  p {
    color: #ffbd00;
    font-size: clamp(18px, 4vw, 30px);
    margin-bottom: 0;
    margin-top: 4rem;
    font-weight: 500;
    a{
      text-decoration:none;
      color: #6f7a80;
    }
  }
  h2{
    margin-top: 0;
  }
  @media (min-width: 867px) {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    .footer-list_containers {
      flex-direction: row;
      width: auto;
    }
  }
`;
