import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";
import { GetInTouch } from "../../components";

function Style({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <h1 className={styles.dynamicTitle}>{jsonData?.style_title}</h1>
            <p>{jsonData?.style_hero_text}</p>
          </div>
        </div>
        <div className="box biografy-section">
          <h2 className={styles.dynamicTitleh2}>
            {jsonData?.style_artist_title}
          </h2>
          <div className="">
            <p>{jsonData?.style_artist_text_left}</p>
            <img
              alt="Benvenuto Cellini, artist portrait"
              src="./assets/benvenuto-cellini.jpg"
              className="toScaleImage"
            />
            <p>{jsonData?.style_artist_text_right}</p>
          </div>
        </div>
        <div className="box art-section">
          <h2 className={styles.dynamicTitleh2}>{jsonData?.style_art_title}</h2>
          <p>{jsonData?.style_art_text}</p>
          <div className="artwork-container">
            <div className="image-container">
              <img
                src="./assets/Ganymede-image.png"
                className="toScaleImage"
                alt="Benvenuto Cellini, artist portrait"
              />
            </div>
            <div className="content-container">
              <h3 className={styles.dynamicTitleh3}>
                {jsonData?.style_artwork_title1}
                <br />
                {jsonData?.style_artwork_title2}
              </h3>
              <p className="subtitle">{jsonData?.style_artwork_subtitle}</p>
            </div>
            <svg viewBox="0 0 1000 500" width="100%" height="auto">
              <polyline
                points="210,150 500,260 620,260"
                stroke="black"
                fill="none"
              />
            </svg>
          </div>
          <div className="artwork-container reversed">
            <div className="image-container">
              <img
                src="./assets/salt-cellar-image.png"
                className="toScaleImage cellar-img"
                alt="Benvenuto Cellini, artist portrait"
              />
            </div>
            <div className="content-container">
              <h3 className={styles.dynamicTitleh3}>
                {jsonData?.style_artwork2_title}
              </h3>
              <p className="subtitle">{jsonData?.style_artwork_subtitle}</p>
            </div>
            <svg viewBox="0 0 1000 500" width="100%" height="auto">
              <polyline
                points="800,210 500,300 350,300"
                stroke="black"
                fill="none"
              />
            </svg>
          </div>
        </div>
        <div className="box closing-section">
          <img
            src="./assets/benvenuto-cellini.jpg"
            className="toScaleImage"
            alt="Benvenuto Cellini, artist portrait"
          />
          <div className="">
            <p>{jsonData?.style_closing_text}</p>
            <p>{jsonData?.style_closing_text2}</p>
          </div>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default Style;
